<template>
    <div class="hello">404 Page Not Found
    </div>
  </template>
  
  <script>
  export default {
    name: '',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang='scss' scoped>
  h1{
    font-size: 0.2rem;
  }
  </style>